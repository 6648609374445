import PropTypes from 'prop-types';
import { useEffect, createContext } from 'react';
// hooks
import useLocalStorage from './../hooks/useLocalStorage';
// utils
import getColorPresets, { colorPresets, defaultPreset } from './../utils/getColorPresets';
// config 
// ----------------------------------------------------------------------
// auths.propTypes = {
//   address: PropTypes.string,
//   role: PropTypes.string,
//   token:PropTypes.string,
// };

const initialState = {
  themeMode:'light',
  auths:'',
  activeuser:'',
  authjwk:'',
  // Mode
  onToggleMode: () => {},
  onChangeMode: () => {}, 
  onChangeauths: (_newaus,_user,_authjwk) => {}, 
  onChangeactiveuser: (_user,_authjwk) => {}, 
  setColor: defaultPreset,
  colorOption: [],  
  onResetSetting: () => {},
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    auths: initialState.auths,
    activeuser:initialState.activeuser,
    authjwk:initialState.authjwk,
  });

  // Mode

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onChangeauths = (newauths,newactiveuser,newauthjwk) => {
   // console.log("auths-newauths:",newauths);
   // console.log("auths-newactiveuser:",newactiveuser); 
    setSettings({
      ...settings,
      auths: newauths,
      activeuser: newactiveuser,
      authjwk:newauthjwk,
    });
  };

  const onChangeactiveuser = (newactiveuser,newauthjwk) => {
    setSettings({
      ...settings,
      activeuser: newactiveuser,
      authjwk:newauthjwk,
    });
  };

  
  // Reset

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      auths: initialState.auths,
      activeuser:initialState.activeuser,
      authjwk:initialState.authjwk, 
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onToggleMode,
        onChangeMode,
        onChangeauths,
        onChangeactiveuser,
        setColor: defaultPreset, // getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),

        // Reset
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
